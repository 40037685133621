import { multitenancy } from './multitenancy/tenants.config';

/**
 * This file is intended to use as environment specificly to build. Tokens are being replaced by the build definition
 */
export const environment = {
    production: true,
    environmentDisplayName: '#{ngapp.environmentDisplayName}#',
    appversion: '3.1.0',
    sessionDurationLimit: '#{ngapp.sessionDurationLimit}#',
    multitenancy: multitenancy,
    tenantConfig: multitenancy[window.location.host],
    isFsmaHoursEnabled: new Date() >= new Date('#{ngapp.newFsmaRuleDate}#'),
    newFsmaHourDateRule: new Date('#{ngapp.newFsmaRuleDate}#'),
};
