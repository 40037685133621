<p-dropdown
    [options]="items"
    [showClear]="false"
    placeholder="{{ getTranslatedPlaceholder() }}"
    [ngModel]="selected"
    autoWidth
    appendTo="body"
    [optionLabel]="displaySelector ? displaySelector : ''"
    (onChange)="selectValue($event)"
    [disabled]="isDisabled"
>
    <ng-template pTemplate="dropdownicon">
        <i class="ph-light ph-caret-down"></i>
    </ng-template>
    <!-- selected item -->
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="selected">
            <div>
                {{ getDisplayText(selected) }}
                <span *ngIf="!getDisplayText(selected)">
                    <span class="text-muted" *ngIf="showPlaceholderWhenEmpty">{{ getTranslatedPlaceholder() }}</span>
                    &nbsp;
                </span>
            </div>
            <div *ngIf="showLabels">
                <div class="inputlabel" *ngFor="let label of getLabels(selected)">
                    <span class="label label-info">{{ label }}</span>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- dropdown list item -->
    <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
            <div>{{ getDisplayText(item) }}&nbsp;</div>
        </div>
    </ng-template>
</p-dropdown>
<!-- for filtering in current language (translatable)
        [sourceProp]="displaySelector"
        filterBy="translatedLabel"
        translateDropdown
 -->
